<template>
  <div class="page">
    <div class="header">
      <div class="center">
        <div class="left">
          <img class="logo" src="../static/images/mf.png" @click="openPage('/doctor')"/>
          <div class="logo-div">
            <div class="logo-title">美蜂医美App</div>
            <div class="logo-desc">想变美，上美蜂，找名医</div>
          </div>
        </div>
        <div class="right">
          <b-link class="link">医生入驻</b-link>
          <b-link class="link" @click="openPage('/')">App下载</b-link>
          <b-link class="link" @click="openPage('/')">联系我们</b-link>
          <span class="link">客服电话 : 400-851-5180</span>
          <div class="exit-btn" @click="exit">退出</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="center">
        <div class="left">
          <div class="avatar-area">
            <img src="../static/doctor/avatar.png" class="avatar" />
            <div>
              <div class="user-nickname">
                {{ info.nickname }} | <span style="color: #999">{{ info.position }}</span>
              </div>
              <div style="font-size: 12px; color: #c89968">普通用户</div>
              <div style="font-size: 12px; color: #999">邀请码：{{ info.code }}</div>
            </div>
          </div>
          <div class="left-menus">
            <div
              class="left-item"
              :class="index == menu? 'active-menu':''"
              v-for="(item, index) in menus"
              :key="index"
              @click="openPage(item.path , index)"
            >
              <img class="item-icon" :src="item.icon" />
              <div style="width: 160px">{{ item.name }}</div>
              <b-icon icon="chevron-right"></b-icon>
            </div>
          </div>
        </div>
        <div style="width: 910px">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Bottom from "../utils/Bottom.vue";
export default {
  data() {
    return {
      active: 0,
      menus: [
        {
          name: "首页",
          path: "/doctorInfo",
          icon: require("../static/join/doctor_ico_home@2x.png"),
        },
        {
          name: "社区",
          path: "/doctorInfo/community",
          icon: require("../static/join/doctor_ico_community@2x.png"),
        },
        {
          name: "档案中心",
          path: "",
          icon: require("../static/join/doctor_ico_file@2x.png"),
        },
        {
          name: "消息中心",
          path: "/doctorInfo/message",
          icon: require("../static/join/doctor_ico_message@2x.png"),
        },
        {
          name: "社区管理",
          path: "",
          icon: require("../static/join/doctor_ico_manage@2x.png"),
        },
        {
          name: "名片管理",
          path: "",
          icon: require("../static/join/doctor_ico_card@2x.png"),
        },
        {
          name: "工具/服务",
          path: "",
          icon: require("../static/join/doctor_ico_tool@2x.png"),
        },
        {
          name: "设置",
          path: "",
          icon: require("../static/join/doctor_ico_set-up@2x.png"), 
        },
      ],
      info: {},
      menu: 0
    };
  },
  components: {
    Bottom,
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.$request.getDoctorInfo({}).then((res) => {
        console.log(res);
        let { data, msg, code } = res;
        if (code == 1) {
          this.info = data;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    openPage(url, i) {      
      if (url != "") {
        this.$router.push({
          path: url,
        });
      }
      this.menu = i
    },
    exit() {
      this.$cache.removeCache("doctorInfo")
      setTimeout(() => {
        this.$router.push({
          path: "/doctorLogin",
        });
      }, 1500)
    }
  },
};
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  min-height: 100vh;
  background: #f9fbfb;
}
.user-nickname {
  font-size: 14px; 
  white-space: nowrap;
}
.content {
  margin: 40px 0;
  width: 100%;
  display: flex;
  justify-content: center;

  .center {
    width: 1200px;
    display: flex;
    .left {
      width: 264px;
      margin-right: 26px;
      height: 620px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
      .avatar-area {
        height: 121px;
        display: flex;
        align-items: center;
        padding: 20px;
      }
      .avatar {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .left-item {
        height: 55px;
        padding-left: 27px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }
      .left-item:hover {
        cursor: pointer;
      }
      .item-icon {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
      .active {
        background: #f2f6ff;
      }
    }
  }
}
.header {
  width: 100%;
  height: 80px;
  background: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
  .center {
    width: 1200px;
    height: 80px;
    display: flex;
    .left {
      width: 400px;
      height: 80px;
      display: flex;
      align-items: center;
      .logo {
        width: 46px;
        height: 46px;
        object-fit: contain;
        margin-right: 14px;
      }
      .logo:hover {
        cursor: pointer;
      }
      .logo-div {
        width: 200px;
      }
    }
    .right {
      width: 800px;
      height: 80px;
      line-height: 80px;
      .link {
        margin-right: 60px;
        text-decoration: none;
        color: #666;
      }
      .exit-btn {
        float: right;
        width: 60px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: linear-gradient(270deg, #fb985a 0%, #f9b359 100%);
        border-radius: 18px;
        margin-top: 22px;
        font-size: 14px;
        color: #fff;
      }
      .exit-btn:hover {
        cursor: pointer;
      }
    }
  }
}
.active-menu {
  background: #F2F6FF;
}
</style>
